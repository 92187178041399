import React from 'react';
import {
  Placeholder,
  VisitorIdentification,
  RestLayoutService,
} from '@sitecore-jss/sitecore-jss-react';
import deepEqual from 'deep-equal';
import Helmet from 'react-helmet';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import MediaQuery from 'react-responsive';
import clsx from 'clsx';
import { useCookies } from 'react-cookie';
import { mediaQueries } from './constants/globalVariable';
import config from './temp/config';
import GoogleTagManagerService from './constants/GoogleTagManagerService';

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import 'bootstrap/dist/css/bootstrap.css';
import './assets/app.scss';

export const layoutService = new RestLayoutService({
  apiHost: config.sitecoreApiHost,
  apiKey: config.sitecoreApiKey,
  siteName: config.jssAppName,
  // tracking: false // if you wish to disable tracking
});

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/
const Layout = ({ context, route }) => {
  const [cookies, setCookie] = useCookies();

  function triggerGTM(googleTagId) {
    if (typeof window !== 'undefined') {
      setCookie('VisitedPages', window.location.href, { path: '/', secure: true });
    }
    if (googleTagId !== '') {
      GoogleTagManagerService(googleTagId);
    }
  }

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet></Helmet>
      <MediaQuery minWidth={`${mediaQueries.isTablet}px`}>
        <Breadcrumb className="breadcrumbMenu">
          {context.Breadcrumb?.map((data, index) => {
            const breadcrumbLength = context.Breadcrumb.length;
            const theme = context.route.fields.ShowSecondaryLogo.value ? 'black' : 'white';
            return (
              <Breadcrumb.Item
                id={theme}
                className={clsx({ active: breadcrumbLength === index + 1 })}
                key={index}
                href={data.Url ? data.Url : ''}
              >
                {data.Title ? data.Title : ''}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </MediaQuery>
      <div className="contactCTA">
        <a className="contactText" href={context.ContactUsDetails?.Url}>
          {context.ContactUsDetails?.Text}
        </a>
      </div>
      {triggerGTM(context.GoogleTagId)}
      <VisitorIdentification />
      <Placeholder name="jss-header" rendering={route} />
      <Placeholder name="jss-main" rendering={route} />
      <Placeholder name="jss-footer" rendering={route} />
    </React.Fragment>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};
export default React.memo(Layout, propsAreEqual);
