import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Modal from 'react-bootstrap/Modal';

import facebookIcon from '../../../assets/images/facebook.svg';
import twitterIcon from '../../../assets/images/twitter.svg';
import linkedInIcon from '../../../assets/images/linkedin.svg';
import signUpArrow from '../../../assets/images/sign-up-arrow.svg';
import youTubeIcon from '../../../assets/images/youtube.svg';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { layoutService } from '../../../Layout';

import get from 'lodash.get';
import './style.scss';

const SignUp = (props) => {
  const [contactPopUpData, setcontactPopUpData] = useState('');
  const signUpDetailsExist = get(props.sitecoreContext, 'SignUpDetails', false);
  useEffect(() => {
    if (props.hasOwnProperty('isSignUpRendered')) {
      signUpDetailsExist ? props.isSignUpRendered(true) : props.isSignUpRendered(false);
    }
  }, []);
  const language = 'en';
  // const sitecoreRoutePath = '/Forms/PopUp Form';
  const sitecoreRoutePath = get(props, 'sitecoreContext.SignUpDetails.SignupLink', '');
  const openPopUp = () => {
    setModalShow(true);
    layoutService.fetchLayoutData(sitecoreRoutePath, language).then(
      (data) => {
        setcontactPopUpData(data);
      },
      [contactPopUpData]
    );
  };

  /* Modal */
  const [modalShow, setModalShow] = React.useState(false);
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Placeholder name="jss-main" rendering={contactPopUpData.sitecore.route} />
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <>
      {signUpDetailsExist ? (
        <div className="sign-up">
          <div className="sign-up-details">
            <div className="signup-details-wrapper">
              <p className="sign-up-text">
                <a className="signUpCta" href="#!" onClick={() => openPopUp()}>
                  {props.sitecoreContext.SignUpDetails.SignupText}{' '}
                  {` ${props.sitecoreContext.SignUpDetails.SignupSubText}`}
                </a>
                <img
                  className="signUpArrow"
                  src={signUpArrow}
                  alt={props.sitecoreContext.SignUpDetails.SignupText}
                  width={135}
                  height={140}
                />
              </p>
            </div>
            <div
              className="sign-up-description anw-label"
              dangerouslySetInnerHTML={{
                __html: props.sitecoreContext.SignUpDetails.SignupDescription,
              }}
            ></div>
          </div>
          <div className="social-icons">
            {props.sitecoreContext.SignUpDetails.SocialMedia?.map((item, index) => {
              let socialIcon;
              switch (item.key) {
                case 'facebook':
                  socialIcon = facebookIcon;
                  break;
                case 'twitter':
                  socialIcon = twitterIcon;
                  break;
                case 'youtube':
                  socialIcon = youTubeIcon;
                  break;
                default:
                  socialIcon = linkedInIcon;
              }
              return (
                <a href={item.link} key={index} title={item.key}>
                  <img src={socialIcon} alt={item.key} width={32} height={32} loading={'lazy'} />
                </a>
              );
            })}
          </div>
          {contactPopUpData !== '' && (
            <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default withSitecoreContext()(SignUp);
