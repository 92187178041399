import React, { Component } from 'react';
import $ from 'jquery';
import get from 'lodash.get';
import SignUp from '../../organisms/SignUp';
import { withTranslation } from 'react-i18next';
import { Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Col, Row } from 'react-bootstrap';
import MediaQuery from 'react-responsive';
import { mediaQueries } from '../../../constants/globalVariable';
import './style.scss';

class Menu extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    if (!get(this.props, 'sitecoreContext.pageEditing', false)) {
      if (window !== undefined) {
        window.addEventListener('scroll', this.handleScroll, true);
      }
    }
  };
  handleScroll = (e) => {
    var scroll;
    var upScroll = 0;
    if (window !== undefined) {
      scroll = $(window).scrollTop();
      window.onscroll = function (e) {
        if ($('.toggle').hasClass('menuClose')) {
          if (this.oldScroll < this.scrollY && this.scrollY > 250) {
            $('#header').hide();
          } else if (this.oldScroll > this.scrollY) {
            upScroll = 0;
            upScroll = upScroll + (this.oldScroll - this.scrollY);
            if (upScroll > 15) {
              $('#header').show();
            }
          }
        }
        this.oldScroll = this.scrollY;
      };
    }
    var attr = $('.toggle-content').attr('open');
    if (scroll > 0) {
      $('#header.white').removeClass('bg-gradient').addClass('headerOpen');
      $('#header .field-image img.navClose').css('display', 'block');
      $('#header.black').removeClass('bg-gradient').addClass('headerOpen toggle-white');
      $('#header').addClass('default-padding');
      $('.breadcrumbMenu').hide();
    } else if (scroll <= 0 && typeof attr !== 'undefined') {
      $('#header.white').removeClass('bg-gradient').addClass('headerOpen');
      $('#header .field-image img.navClose').css('display', 'none');
      $('.breadcrumbMenu').hide();
      $('#header').removeClass('default-padding');
      $('#header .field-image img.navClose').css('display', 'block');
    } else if (scroll <= 0 && typeof attr === 'undefined') {
      $('#header.white').removeClass('headerOpen').addClass('bg-gradient');
      $('#header.black').removeClass('headerOpen toggle-white');
      $('#header .field-image img.navClose').css('display', 'none');
      $('#header').removeClass('default-padding');
      $('.breadcrumbMenu').show();
    }
  };
  toggle(e, linkType) {
    if (e.target !== null || e.target !== undefined) {
      $('.items .item,.main-nav-item').removeClass('active');
      if (linkType === 'javascript' || linkType === 'internal') {
        e.stopPropagation();
        e.target.classList.add('active');
        $('.main-nav-item.active').parent('.item').addClass('active');
        if (window && window.innerWidth < 768) {
          let activeitem = $('#header .toggle .toggle-header + .mainNav .main-nav-item.active');
          if (activeitem.offset() !== undefined) {
            let elementOffset = activeitem.offset().top,
              headerHeight = $('#header').height(),
              scrollTop = elementOffset - headerHeight;
            $('#header .toggle .toggle-header + .mainNav').scrollTop(scrollTop);
          }
        }
      }
    }
  }
  headerToggle() {
    $('#header').addClass('headerOpen');
    $('.item').removeClass('active');
    var attr = $('.toggle-content').attr('open');
    if (typeof attr === 'open' || 'undefined') {
      $('.item').removeClass('active');
    }
  }
  menuToggle() {
    $('.item').removeClass('active');
    if (window !== undefined) {
      var scroll = $(window).scrollTop();
    }
    if ($('.toggle').hasClass('menuClose')) {
      $('body').css('overflow', 'hidden');
      $('#root .breadcrumbMenu').css('display', 'none');
      $('#header').addClass('headerOpen').removeClass('bg-gradient');
      $('.toggle').removeClass('menuClose').addClass('menuOpen');
      $('#header .field-image img.navClose').css('display', 'block');
    } else {
      $('body').css('overflow-y', 'scroll');
      $('#header').removeClass('headerOpen');
      $('.toggle').removeClass('menuOpen').addClass('menuClose');
      if (scroll > 0) {
        $('#root .breadcrumbMenu').css('display', 'none');
        $('#header').addClass('headerOpen').removeClass('bg-gradient');
        $('#header .field-image img.navClose').css('display', 'block');
      } else {
        $('#root .breadcrumbMenu').css('display', 'block');
        $('#header .field-image img.navClose').css('display', 'none');
        $('#header.white').removeClass('headerOpen').addClass('bg-gradient');
        $('#header.white').removeClass('headerOpen bg-gradient');
        $('#header.black').removeClass('headerOpen toggle-white');
      }
    }
    $(
      '.mainNav .items .item:nth-child(1),.mainNav .items .item:nth-child(1) .main-nav-item'
    ).addClass('active');
  }
  render() {
    const { t } = this.props;
    return (
      <div
        className="component toggle menuClose"
        data-properties='{"easing":"easeInOutSine","speed":400,"expandOnHover":false,"expandedByDefault":false}'
      >
        <details className="toggle-content clearfix">
          <summary className="toggle-header" tabIndex="0">
            <span className="toggle-label" onClick={this.menuToggle}>
              {' '}
              {t('menu')}{' '}
            </span>
          </summary>
          <div className="mainNav container">
            <Row>
              <Col md={12}>
                <div className="page-list">
                  <div className="component-content">
                    <ul className="items">
                      {this.props.menuDetails.fields.MenuLinks.map((postData, index) => {
                        let linkType = get(postData, 'fields.Link.value.linktype', 'javascript');

                        return (
                          <li key={index} className="item ">
                            {' '}
                            <MediaQuery
                              minWidth={`${mediaQueries.isMobile}px`}
                              maxWidth={`${mediaQueries.isTablet - 1}px`}
                            >
                              <a
                                onClick={(e) => this.toggle(e, linkType)}
                                href={
                                  linkType === 'javascript'
                                    ? '#'
                                    : get(postData, 'fields.Link.value.href', '#')
                                }
                                className="main-nav-item"
                              >
                                {postData.fields.Link.value.text}
                              </a>
                            </MediaQuery>
                            <MediaQuery minWidth={`${mediaQueries.isTablet}px`}>
                              <a
                                onMouseOver={(e) => this.toggle(e, linkType)}
                                href={
                                  linkType === 'javascript'
                                    ? '#'
                                    : get(postData, 'fields.Link.value.href', '#')
                                }
                                className="main-nav-item"
                              >
                                {postData.fields.Link.value.text}
                              </a>
                            </MediaQuery>
                            <div className="subnav-container">
                              <div className="inner-wrap">
                                <div className="subnav-list">
                                  <div className="subnav-link-list">
                                    <ul className="subnav-ul">
                                      {postData.fields.MenuSubLinks?.map((element, i) => {
                                        return (
                                          <li key={i}>
                                            <Link field={element.fields.Link} className="submenu" />
                                            {element.fields.Abstract.value !== '' && (
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html: element.fields.Abstract.value,
                                                }}
                                                className="field-summary"
                                              ></span>
                                            )}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="row">
              <div className="col-12 col-md-7 offset-md-5 signupLeft">
                <SignUp></SignUp>
              </div>
            </div>
          </div>
        </details>
      </div>
    );
  }
}

export default withTranslation()(withSitecoreContext()(Menu));
