import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import get from 'lodash.get';

const Logo = (props) => {
  const logo = get(props, 'sitecoreContext.route.fields.ShowSecondaryLogo.value')
    ? 'black'
    : 'white';
  let LogoHref = get(props, 'logoDetails.fields.LogoLink.value.href', '/');
  if (LogoHref === '') {
    LogoHref = '/';
  }
  const LogoTitle = get(props, 'logoDetails.fields.LogoLink.value.title', 'Logo');

  if (logo === 'black') {
    return (
      <div className={`field-image ${logo}`}>
        <a href={LogoHref} title={LogoTitle}>
          <img
            className={`navOpen ${logo}`}
            src={props.logoDetails.fields.SecondaryLogo.value.src}
            alt={props.logoDetails.fields.SecondaryLogo.value.alt}
            width={145}
            height={58}
          ></img>
          <img
            className={`navClose ${logo}`}
            src={props.logoDetails.fields.Logo.value.src}
            alt={props.logoDetails.fields.Logo.value.alt}
            width={145}
            height={58}
          ></img>
        </a>
      </div>
    );
  } else {
    return (
      <div className={`field-image ${logo}`}>
        <a href={LogoHref} title={LogoTitle}>
          <img
            className={`${logo}`}
            src={props.logoDetails.fields.Logo.value.src}
            alt={props.logoDetails.fields.Logo.value.alt}
            width={145}
            height={58}
          ></img>
        </a>
      </div>
    );
  }
};

export default withSitecoreContext()(Logo);
