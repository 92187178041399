const tagExists = (f, j) => {
  var scripts = f.parentNode.getElementsByTagName('script');
  for (var i = scripts.length; i--; ) {
    if (scripts[i].src === j.src) return true;
  }
  return false;
};

const GoogleTagManager = function (gtm_id) {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    if (!tagExists(f, j)) {
      f.parentNode.insertBefore(j, f);
    }
  })(window, document, 'script', 'dataLayer', gtm_id);
};

const GoogleTagManagerService = (gtmId) => {
  if (typeof window !== 'undefined') {
    GoogleTagManager(gtmId);
    gtag('js', new Date());
    gtag('config', gtmId);
  }
};

export default GoogleTagManagerService;
