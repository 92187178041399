import { ActionTypes } from '../constants/action-type';
import includes from 'lodash.includes';

const initialState = {
  jobs: [],
  filter: {
    department: [],
    location: [],
    jobType: [],
  },
  department: [],
};

const getFilterType = (data, filterName) => {
  let filterData = [];
  data.map((item) => {
    // Adding checks as data for location is received in string array rather than array
    if (filterName === 'Location' && Array.isArray(JSON.parse(item[filterName]))) {
      JSON.parse(item[filterName]).map((loc, index) => {
        if (!includes(filterData, loc.Address)) {
          filterData.push(loc.Address);
        }
      });
    } else {
      if (!includes(filterData, item[filterName])) {
        filterData.push(item[filterName]);
      }
    }
  });
  return filterData;
};

export const jobReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.STORE_JOBLISTINGS:
      return {
        ...state,
        jobs: payload.Result,
        filter: {
          department: getFilterType(payload.Result, 'Department'),
          location: getFilterType(payload.Result, 'Location'),
          // jobType: getFilterType(payload.Result, 'JobType'),
        },
      };
    default:
      return state;
  }
};
