const mediaQueries = {
  isMobile: '320',
  isLandScape: '640',
  isTablet: '768',
  isDesktop: '992',
  isLargeDesktop: '1024',
  isExtraLargeDesktop: '1300',
  isExtraExtraLargeDesktop: '1900',
};
export { mediaQueries };
