import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';

/**
 * Function for checking if props exist for the given component
 * Params: props -> Received by the component
 */
const checkProps = (props) => {
  return isEmpty(get(props, 'fields', '')) ? isEditorActive() : true;
};

export { checkProps };
